<template>
    <div class="chart-container" style="height: 230px;">
        <apexchart width="100%" height="230px" type="heatmap" :options="options" :series="filtered_series"></apexchart>
    </div>
</template>

<script>


import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

export default {
    props: {
        device: Object,
        reasons: Array,
        module: String,
        modules: Array,
        title: { type: String, required: false, default: 'Alarm History'} 
    }
    ,
    data: function(){
        return {
            series: [],
            options: {
                chart: {
                    height: 200,
                    type: 'heatmap',
                },
                colors: ['#dedede'],
                plotOptions: {
                    heatmap: {
                        shadeIntensity: 1,
                        enableShades: false,
                        colorScale: {
                            ranges: [
                            {
                                from: 0,
                                to: 19,
                                name: 'Normal',
                                color: '#89D389'
                            },
                            {
                                from: 20,
                                to: 80,
                                name: 'Warning',
                                color: '#FEC746'
                            },
                            {
                                from: 81,
                                to: 100,
                                name: 'Critical',
                                color: '#FE8A8C'
                            }]
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    type: 'category',
                },
                title: {
                    text: this.title,
                    align: 'left',
                    margin: 30,
                    offsetX: 20,
                    offsetY: 20,
                    floating: false,
                    style: {
                    fontSize:  '20px',
                    color:  '#263238'
                    },
                },
                
                tooltip: {
                    enabled: true,
                    shared:true,
                    x: {
                        show: true,
                        formatter: function(val, cfg){
                            //console.log(cfg)
                            return val;
                            //return moment(cfg.w.config.series[cfg.seriesIndex].data[cfg.dataPointIndex].t).format("YYYY-MM-DD")
                        }
                    },
                    y: {
                        show:true,
                        formatter: function(a,b,c){
                            if(a < 20){
                                return "Normal"
                            }
                            if(a < 80){
                                return "Warning"
                            }
                            else {
                                return "Critical"
                            }
                        },
                        title: {
                            formatter: function(val,cfg){
                                //console.log(val,cfg)
                                return cfg.w.config.series[cfg.seriesIndex].data[cfg.dataPointIndex].t.format("YYYY-MM-DD")
                            }
                        }
                    }
                }
            }
        }
    },
    computed: {
        filtered_series: function(){
            var vm = this;
            var new_series = []
            var start = new Date().getMilliseconds();
            _(vm.series).each(function(series){
                new_series.push({
                    name: series.name,
                    data: _.map(series.data, function(datum, index){

                                var my_max = _.reduce(vm.reasons, function(acc, it){
                                    return Math.max(acc, datum[it] ? datum[it].level : -1 );
                                }, -1)

                                return {
                                    x: getCategory(datum, index),
                                    y: my_max,
                                    t: moment(datum.t)
                                }
                            })
                })
            })
            
            new_series = new_series.reverse()
            return new_series;
        }
    },
    methods:{
        loadAlarms: function(){
                var vm = this;
                var ctx = this;
                var url = window.SMARTPLACES.urls.common.get_for(ctx.device).alarm_heatmap + '?';

                if(this.module){
                    url = url + "module=" + this.module
                }

                if(this.modules){
                    var modules_params = "module=" + _.join(this.modules, '&module=')
                    url = url + modules_params
                }

                return axios.get(url)
                    .then(function(resp){
                        var list = prepend_append_align_days(resp.data)
                        var series = make_weekday_series(list)
                        Vue.set(vm, "series", series);
                        //console.log(series)
                    })
                    
            }
    }
    ,
    watch: {
    }
    ,
    created: function(){
        this.loadAlarms();
    }
    ,
    mounted: function(){
    }
}

function getCategory(datum, index){
    var date = moment(datum.t)
    var wk = date.isoWeek()
    var prefix = index == 0 || wk == 1 ? date.year() + " " : ""

    var start_of_week = date.isoWeekday(7); // set to Sunday

    return prefix + start_of_week.format("MMM DD")
}


function extract_year_week_categories(series)
{ 
    var series_0 = series[0]
    var series_data = series_0.data
    return _.map(series_data, function(it){
        
    })
}

function make_weekday_series(list)
{
    return _(list).groupBy(function(it){
                    var date = moment(it.t)
                    return date.format('ddd')
                }).mapValues(function(value, id){
                    return {
                        name: id,
                        data: value
                    }
                }) //attach id to object
                .values() //get the values of the result
                .sortBy([function(it){
                    return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].indexOf(it.name)
                }])
                .value() //unwrap array of objects
}

/**
 * returns new array
 */
function prepend_append_align_days(series)
{
    var output = [];
    var orig_day_1 = series[0];
    var tsmoSt = moment(orig_day_1.t)
    var year_start_dow = tsmoSt.isoWeekday() // 1-7
    //console.log("Master list starts: "  + year_start_dow, orig_day_1)
    // assume sunday start: TODO localise
    for(var i=1;i<year_start_dow;i++){

        var clone_day_1 = {
            t: moment(tsmoSt).subtract(year_start_dow-i, 'days' ).format('YYYY-MM-DD')
        }
        _(orig_day_1).keys().filter(function(k){ return k != 't'}).each(function(k){
            clone_day_1[k] = { level: -1, type: 'pad' }
        })
        //console.log("Master list prepend: "  + year_start_dow-i, clone_day_1)
        output.unshift(clone_day_1)
    }
    
    output = output.concat(series)

    var last_real_day = series[series.length-1];
    var tmsoEn = moment(last_real_day.t);
    var year_end_dow = tmsoEn.isoWeekday() // 1-7
    //console.log("Master list ends: "  + year_end_dow, last_real_day)
    for(var i=0;i<7-year_end_dow;i++){
        var clone_day_n = {
            t: moment(tmsoEn).add(i+1, 'days').format('YYYY-MM-DD')
        }
        _(last_real_day).keys().filter(function(k){ return k != 't'}).each(function(k){
            clone_day_n[k] = { level: -1, type: 'pad' }
        })
        //console.log("Master list append: "  + i, clone_day_n)
        output.push(clone_day_n)
    }

    return output;
}
    
</script>

<style>


</style>