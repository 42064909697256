<template>
  <div class="list-group list-group-flush">
    <a class="list-group-item">
      <i class="fa fa-fw" v-bind:class="last_data_point.icon"></i>
      <small class="">Last Data Point: </small>
      <strong>{{ last_data_point.time | fromNow }}</strong>
      <span v-if="last_data_point.txs_until_next < 3">
        <i
          class="fa fa-fw fa-clock-o"
          @click="ui.showNextSeen = !ui.showNextSeen"
        >
        </i>
        <em v-if="ui.showNextSeen">
          Next Expected: {{ last_data_point.next_seen | fromNow }}
        </em>
      </span>
    </a>
    <a class="list-group-item">
      <i class="fa fa-fw" v-bind:class="current_status.classes"></i>
      <small class="">Current Status: </small>
      <strong>{{ current_status.status }}</strong>
    </a>
    <a
      class="list-group-item"
      v-for="(alarm, $index) in current_alarms"
      v-bind:key="$index"
      v-bind:class="alarm.bs_list_class"
    >
      <i class="fa fa-fw" v-bind:class="alarm.status.classes"></i>
      &nbsp;
      <i class="fa fa-fw" v-bind:class="alarm.alarm_model.icon"></i>
      &nbsp;
      {{ alarm.text }}
    </a>
  </div>
</template>

<script>
export default {
  props: ["device"],
  computed: {
    last_data_point: function() {
      var last_seen = _.get(this.device, "current_state.last_seen");
      if (!last_seen) {
        return {
          icon: "fa-exclamation-triangle text-warning",
          status: "No Data",
          time: null,
          txs_until_next: Number.MAX_VALUE,
          next_seen: null,
        };
      }

      var then = moment(last_seen * 1000);
      var now = moment();
      var n = 0;
      while (then.isBefore(now)) {
        n++;
        then.add(this.device.frequency, "seconds");
      }

      var next_seen = then.unix();

      var deadman_alarm = _.get(
        this.device,
        "current_state.alarms.active_alarms.deadman"
      );
      if (deadman_alarm && deadman_alarm.level > 0) {
        return {
          icon: "fa-exclamation-triangle text-danger",
          status: "Missed Transmissions",
          time: last_seen,
          next_seen: next_seen,
          txs_until_next: n,
        };
      }

      return {
        icon: "fa-check text-success",
        status: "OK",
        time: last_seen,
        next_seen: next_seen,
        txs_until_next: n,
      };
    },
    current_status: function() {
      var alarm_state = _.get(this.device, "current_state.alarms");
      if (!alarm_state) {
        return {
          classes: "fa-exclamation-triangle text-warning",
          status: "Unknown",
        };
      }

      var level = _.get(alarm_state, "current_level");

      return getClassesAndStatus(level);
    },
    current_alarms: function() {
      var alarm_state = _.get(this.device, "current_state.alarms");
      if (!alarm_state) {
        return [];
      }

      var current_alarms = _.get(alarm_state, "active_alarms");

      var alarms = _(current_alarms)
        .map((alarm, alarm_type) => {
          var status = getClassesAndStatus(alarm.level);

          return {
            level: alarm.level,
            text: alarm.text,
            alarm_type: alarm_type,
            alarm_model: window.SMART_PROPERTY.models[alarm_type],
            status: status,
            bs_list_class: "list-group-item-" + status.bs_color,
          };
        })
        .filter((it) => {
          return it.level > 0;
        })
        .value();

      return alarms;
    },
  },
  methods: {},
  data: function() {
    return {
      ui: {
        showNextSeen: false,
      },
    };
  },
  mounted: function() {
    //var vm = this;
  },
};

function getClassesAndStatus(level) {
  /*
   * Vue will make a reactive object when an number is missing to 'null' is an empty object, tested with NaN.
   */

  if (!_.isNumber(level) || level == 0 || level == -1) {
    // -1 for no alarms yet
    return {
      bs_color: "success",
      classes: "fa-check text-success",
      status: "Normal",
    };
  } else if (level > 80) {
    return {
      bs_color: "danger",
      classes: "fa-exclamation-triangle text-danger",
      status: "Critical",
    };
  } else {
    return {
      bs_color: "warning",
      classes: "fa-exclamation-circle text-warning",
      status: "Warning",
    };
  }
}
</script>

<style></style>
