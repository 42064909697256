/*jshint esversion: 6 */
import Vue from "vue";

import Datepicker from "vuejs-datepicker";
Vue.component("datepicker", Datepicker);

const app = "Pinacl SmartPlaces";

import TsVal from "../static/components/sc-timeseries-value.vue";
import ScMap from "../static/components/sc-map-single.vue";
import PopupContent from "../static/components/sc-popup-content.vue";
import DeviceSelector from "../static/components/sc-device-selector.vue";
import DeviceLink from "../static/components/sc-device-link.vue";
import CarparkLink from "../static/components/sc-carpark-link.vue";
import PropertyAreaLink from "../static/components/sc-property-area-link.vue";
import NotesPanel from "../static/components/sc-notes-panel.vue";
import ScMapHousing from "../static/components/sc-map-housing.vue";
import ScAlarmList from "../static/components/sc-alarm-list.vue";
import ScAlarmHeatmap from "../static/components/sc-alarm-heatmap.vue";
import ScAlarmStatus from "../static/components/sc-alarm-status.vue";
import ScDefaultModal from "../static/components/sc-default-modal.vue";
import ScChart from "../static/components/sc-device-chart.vue";
import WatchIcon from "../static/components/watch_icon.vue";
import PhotosPanel from "../static/components/sc-photos-panel.vue";
import CurrentStatus from "../static/components/sc-current-status.vue";
import EditInPlaceField from "../static/components/edit-in-place-field.vue";
import ConfigPanel from "../static/components/config-panel.vue";
import TempusGuidance from "../static/components/tempus_guidance.vue";
import ScDateRangePicker from "../static/components/sc-date-range-picker.vue";
import ScDateRangeControls from "../static/components/sc-date-range-controls.vue";
import ScGridEyeHeatMap from "../static/components/sc-grideye-heatmap.vue";
import ScCommon from "./sc-common.js";

Vue.component("sc-timeseries-value", TsVal);
Vue.component("sc-map-single", ScMap);
Vue.component("sc-map-housing", ScMapHousing);
Vue.component("sc-popup-content", PopupContent);
Vue.component("sc-device-selector", DeviceSelector);
Vue.component("sc-device-link", DeviceLink);
Vue.component("sc-carpark-link", CarparkLink);
Vue.component("sc-property-area-link", PropertyAreaLink);
Vue.component("sc-notes-panel", NotesPanel);
Vue.component("sc-alarm-list", ScAlarmList);
Vue.component("sc-alarm-heatmap", ScAlarmHeatmap);
Vue.component("sc-alarm-status", ScAlarmStatus);
Vue.component("sc-modal", ScDefaultModal);
Vue.component("sc-device-chart", ScChart);
Vue.component("sc-photos-panel", PhotosPanel);
Vue.component("watch_icon", WatchIcon);
Vue.component("sc-current-status", CurrentStatus);
Vue.component("edit-in-place", EditInPlaceField);
Vue.component("config-panel", ConfigPanel);
Vue.component("tempus-guidance", TempusGuidance);
Vue.component("date-range-picker", ScDateRangePicker);
Vue.component("date-range-controls", ScDateRangeControls);
Vue.component("sc-grideye-heatmap", ScGridEyeHeatMap);

global.Vue = Vue;
global.ScCommon = ScCommon; // attach this module to window where plain pages can find it.

import "leaflet/dist/leaflet.css";
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import "leaflet.markercluster/dist/MarkerCluster.css";

import "./style/reports.less";
import "./style/features.less";

import "./vendor_libs.js";
import "./registry_components.js";

import tempus_functions from "./tempus_functions.js";
import util_functions from "./utils.js";

import DateFunctions from "./date_component_functions.js";

var PSP = {}; // pinacl smart places /home/rob/Projects/top/iot-stack/codebase/src/webapp/static/components/registry
global.PSP = PSP;

PSP.tempus = tempus_functions;
PSP.utils = util_functions;
PSP.DateFunctions = DateFunctions;

export default {
  TsVal: TsVal,
  ScMap: ScMap,
  ScMapHousing: ScMapHousing,
  PopupContent: PopupContent,
  DeviceSelector: DeviceSelector,
  DeviceLink: DeviceLink,
  CarparkLink: CarparkLink,
  PropertyAreaLink: PropertyAreaLink,
  NotesPanel: NotesPanel,
  ScAlarmList: ScAlarmList,
  ScAlarmHeatmap: ScAlarmHeatmap,
  ScAlarmStatus: ScAlarmStatus,
  ScDefaultModal: ScDefaultModal,
  ScChart: ScChart,
  PhotosPanel: PhotosPanel,
  WatchIcon: WatchIcon,
  CurrentStatus: CurrentStatus,
  EditInPlaceField: EditInPlaceField,
  ConfigPanel: ConfigPanel,
  TempusGuidance: TempusGuidance,
  ScCommon: ScCommon,
  PSP: PSP,
  ScDateRangePicker: ScDateRangePicker,
  ScDateRangeControls: ScDateRangeControls,
  ScGridEyeHeatMap: ScGridEyeHeatMap
};
